import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const getImage = graphql`
  {
    Project3: file(relativePath: { eq: "project33.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    Project3Mbl: file(relativePath: { eq: "project3-mbl.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Project3 = () => {
  const data = useStaticQuery(getImage)
  return (
    <>
      <section className="project-section section-3">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-lg-6 d-lg-none">
              <div className="small-media-list">
                <Img
                  fluid={data.Project3Mbl.childImageSharp.fluid}
                  alt="Pandigita"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="project-text">
                <div className="topic">Health - Austin Holistic Fitness</div>
                <h3>8-Week Premium Fitness Coaching in an app</h3>
                <p>
                  Austin’s premier fitness coach Jeremy Robinson, known from TV
                  appearances, offers a demanding but effective 8-week
                  nutrition, fitness and mental health coaching programme. When
                  he wanted his clients to be able to access his programme and
                  view their progress from anywhere, he turned to us.
                </p>
                <p className="ul-title">OUR WORK</p>
                <ul>
                  <li>Consulting, Conceptualisation, Strategy</li>
                  <li>Complete iOS and Android app in react-native</li>
                  <li>
                    Customisation of existing user management to be used in app
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="right-part">
          <div className="media-list">
            <div className="project3-img">
              <Img
                fluid={data.Project3.childImageSharp.fluid}
                alt="Pandigita"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Project3
