import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const getImage = graphql`
  {
    Project5: file(relativePath: { eq: "project5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    Project5Mbl: file(relativePath: { eq: "project5-mbl.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Project5 = () => {
  const data = useStaticQuery(getImage)
  return (
    <>
      <section className="project-section section-5">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-lg-6 d-lg-none">
              <div className="small-media-list">
                <Img
                  fluid={data.Project5Mbl.childImageSharp.fluid}
                  alt="Pandigita"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="project-text">
                <div className="topic">EDTECH - Product Ownership</div>
                <h3>Kiron: Free University Courses for Refugees</h3>
                <p>
                  Kiron is a revolutionary online learning NGO that offers
                  university level courses in organized curricula for free to
                  refugees. Pandigita’s founder was also a founding team member and product owner with Kiron. Besides the web platform, he managed the Kiron VR
                  learning project.
                </p>
                <p className="ul-title">OUR WORK</p>
                <ul>
                  <li>Product Ownership Web - Learning Management System</li>
                  <li>Product Ownership VR - Immersive Language Learning</li>
                  <li>Kiron Management Circle</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="right-part">
          <div className="media-list">
            <div className="project5-img">
              <Img
                fluid={data.Project5.childImageSharp.fluid}
                alt="Pandigita"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Project5
