import React, { useState } from "react"
import BannerImg from "../images/banner-img-2.png"
import { Link } from "react-scroll"

import DownArrow from "../images/down-arrow.png"

const Banner = () => {
  const [show, setShow] = useState(false)

  const showModal = e => {
    // e.preventDefault()
    // setShow(true)
    window.location.href = "/book"
  }

  const closeModal = () => {
    setShow(false)
  }

  return (
    <section className="banner-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 left-column">
            <div className="banner-text">
              <h1>
                Your App, <br />
                Delighting Your Customers, <br />
                On Any Platform
              </h1>
              <p>
                We build delightful mobile apps, web apps, <br />
                and the digital strategies behind them.
              </p>
              <div className="btn-groups">
                <button className="btn btn-primary" onClick={showModal}>
                  Book Free Consulting
                </button>

                <div
                  className={`modal-container box-shadow ${
                    show ? "show" : "hidden"
                  }`}
                >
                  <span className="close" onClick={closeModal}>
                    X
                  </span>
                  <h3 className="title">Select Schedule</h3>
                  <div className="modal-content">
                    <iframe
                      src="https://calendly.com/pandigita/consulting"
                      height="100%"
                    ></iframe>
                  </div>
                </div>

                {/* <Link
                  className="btn btn-primary white-bg"
                  to="contact-form-section"
                  smooth={true}
                  duration={1000}
                >
                  Get Quote
                </Link> */}
              </div>
              {/* <a href="#" className="text-link">
                Why we offer <span>free consulting</span> & how you benefit
                <img src={DownArrow} alt="Pandigita" />
              </a> */}
            </div>
          </div>
          <div className="col-lg-7 right-column">
            <div className="banner-img">
              <img src={BannerImg} alt="Pandigita" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
