import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const getImage = graphql`
  {
    Project4: file(relativePath: { eq: "project4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    Project4Mbl: file(relativePath: { eq: "project4-mbl.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Project4 = () => {
  const data = useStaticQuery(getImage)
  return (
    <>
      <section className="project-section section-4">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6 d-lg-none">
              <div className="small-media-list">
                <Img
                  fluid={data.Project4Mbl.childImageSharp.fluid}
                  alt="Pandigita"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="project-text">
                <div className="topic">EDTECH - INTAO</div>
                <h3>
                  Making corporate training stick using a chatbot learning coach
                  and daily microlessons
                </h3>
                <p>
                  Did you know that 90% of corporate training content is
                  forgotten? We jumped on this huge problem to co-develop this
                  innovative edTech app with the founders, two top management
                  coaches. The solution? Short but daily lessons using an
                  interactive chatbot format and microlessons."
                </p>
                <p className="ul-title">OUR WORK</p>
                <ul>
                  <li>Product management</li>
                  <li>Style & user interface in react-native</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="left-part">
          <div className="media-list">
            <div className="project4-img">
              <Img
                fluid={data.Project4.childImageSharp.fluid}
                alt="Pandigita"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Project4
