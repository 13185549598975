import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const getImage = graphql`
  {
    Project2: file(relativePath: { eq: "project2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    Project2Mbl: file(relativePath: { eq: "project2-mbl.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Project2 = () => {
  const data = useStaticQuery(getImage)
  return (
    <>
      <section className="project-section section-2">
        <div className="container">
          <div className="row justify-content-lg-end">
            <div className="col-lg-6 d-lg-none">
              <div className="small-media-list">
                <Img
                  fluid={data.Project2Mbl.childImageSharp.fluid}
                  alt="Pandigita"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="project-text">
                <div className="topic">MyTestHero - education | eCommerce</div>
                <h3>Conversion focused booking system</h3>
                <p>
                  MyTestHero offers an innovative education business in China -
                  IETLS Exam preparation with official IELTS Examiners. In close
                  cooperation we conceptualized and developed a system to
                  drastically reduce the administrative burden of the company by
                  letting customers book the instructors directly while also
                  focusing on a smooth upsell and payment experience to increase
                  conversions.
                </p>
                <p className="ul-title">OUR WORK</p>
                <ul>
                  <li>Business operations & digital strategy consulting</li>
                  <li>Customer facing booking system</li>
                  <li>Instructor dashboard & user management</li>
                  <li>Complete Backends</li>
                  <li>
                    Checkout with credit card or Alipay (Chinese provider)
                  </li>
                  <li>Smooth access to Chinese market using proxy server</li>
                  <li>Multi-language (English & Chinese)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="left-part">
          <div className="media-list">
            <div className="project2-img">
              <Img
                fluid={data.Project2.childImageSharp.fluid}
                alt="Pandigita"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Project2
