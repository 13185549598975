import React from "react"

import PlatformImg from "../images/platform.svg"

const AboutSection2 = () => {
  return (
    <>
      <section className="about-section-2">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div className="code-platform small-mb-50">
                <div className="code-img">
                  <img src={PlatformImg} alt="Pandigita" />
                </div>
                <div className="code-platform-text-box box-shadow">
                  <h1>
                    Your App - compiled for iOS, Android and web, from one code
                    base
                  </h1>
                  <p>
                    It’s called react-native-web. It’s been around for years,
                    but only recently reached critical ecosystem adoption &
                    maturity. We’re the only agency we know of that specializes
                    in this “3-in-1” tech that can save you development cost,
                    time cost and headaches.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="about-text-2">
                <h1>The right technology for your project</h1>
                <p>
                  The right technology choice can be a major competitive,
                  organizational and strategic advantage. We will always align
                  the right tech with your project and business goals.
                </p>
                <p className="ul-title">We're fluent in..</p>
                <ul>
                  <li>React & Node Web Apps</li>
                  <li>react-native & react-native-web (all platforms)</li>
                  <li>Swift and C# (iOS)</li>
                  <li>Kotlin and Java (Android)</li>
                  <li>Flutter (all platforms)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutSection2
