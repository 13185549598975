import React from "react"

import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Banner from "../components/Banner"
import IntroSection from "../components/IntroSection"
import AboutSection1 from "../components/AboutSection1"
import AboutSection2 from "../components/AboutSection2"
import Project1 from "../components/Projects/Project1"
import Project2 from "../components/Projects/Project2"
import Project3 from "../components/Projects/Project3"
import Project4 from "../components/Projects/Project4"
import Project5 from "../components/Projects/Project5"
import AboutSection3 from "../components/AboutSection3"
import ContactSection from "../components/ContactSection"
import FooterSection from "../components/FooterSection"

import SEO from "../components/SEO"
import ToTop from "../components/ToTop"

const Home = () => {
  return (
    <>
      <Layout>
        <SEO title="Pandigita - Delightful Mobile & Web App Experiences" />
        <Navbar />
        <Banner />
        <IntroSection />
        <AboutSection1 />
        <AboutSection2 />
        <Project1 />
        <Project2 />
        <Project3 />
        <Project4 />
        <Project5 />
        {/* <AboutSection3 /> */}
        <ContactSection />
        <FooterSection />
      </Layout>
    </>
  )
}

export default Home
