import React from "react"

import img1 from "../images/img1.svg"
import img2 from "../images/img2.svg"

const AboutSection1 = () => {
  return (
    <>
      <section className="about-section-1" id="about-section-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-text-box small-mb-100">
                <div className="about-media">
                  {/* <Img fluid={aboutImage} alt="Pandigita" /> */}
                  <img src={img1} alt="Pandigita" />
                </div>
                <h1 style={{ marginBottom: "20px" }}>
                  Top Tier Global Developer Talent
                </h1>
                <p>
                  We think there’s only one way left to hire the few true code
                  ninjas, the top 3%: It’s going global. This way we have a
                  significant talent edge over anyone hiring from only one
                  country - be it local or abroad.
                </p>
                <p>
                  For you, this talent edge means higher quality and project
                  success. Where else can you, at a normal budget, hire devs who
                  worked for brands like Mastercard, Redbull, & Adidas?
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-text-box">
                <div className="about-media">
                  {/* <Img fluid={aboutVideo} alt="Pandigita" /> */}
                  {/* <img src={img2} alt="Pandigita" /> */}
                  <div style={{height:"130px"}}></div>
                </div>
                <h1 style={{ marginBottom: "20px" }}>
                  Communication, culture fit and aligned vision
                </h1>
                <p>
                  These are our main ingredients to make project management with
                  you a success. So at pandigita, our founder is directly
                  involved in your project.
                </p>
                <p>
                  A German originally, he has many international digital
                  projects under his belt, including being Founding Team Member
                  at a start-up that scaled from 0 to 80 employees. He also
                  holds a Master’s from the University of Cambridge.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutSection1
