import React, { useState } from "react"

import MasterCard from "../images/company-logo/mastercard.svg"
import Adidas from "../images/company-logo/adidas.svg"
import Redbull from "../images/company-logo/redbull.svg"
import IOS from "../images/company-logo/ios.svg"
import Android from "../images/company-logo/android.svg"
import WWW from "../images/company-logo/www.svg"

const ExpandableText = ({children}) => {

  const [expanding, setExpanding] = useState(false);

  const toggleExpanding = (event) => {
    event.preventDefault();
    setExpanding(!expanding);
  }

  return (
    <div style={{position: "relative"}}>
      <p style={{ fontSize: "15px", height: expanding ? "auto" : "84px", overflow: "hidden", position: "relative" }}>
      {children}
      </p>
      <a href="#/" onClick={toggleExpanding} style={{position: "absolute", zIndex: 10, bottom: -40, right: 10, backgroundColor: "#fff", fontWeight: 500}}>{expanding ? "show less" : "show more"}</a>
    </div>
  )
}

const IntroSection = () => {
  return (
    <section className="intro-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-text-box tablet-mb-60">
              <div className="intro-btn box-shadow">Rockstar Developers</div>
              <ul className="logo">
                <li>
                  <img src={MasterCard} alt="Pandigita" />
                </li>
                <li>
                  <img src={Adidas} alt="Pandigita" />
                </li>
                <li>
                  <img src={Redbull} alt="Pandigita" />
                </li>
              </ul>
              <ExpandableText>
                <span>
                  Brands they worked for{" "}
                  <span style={{ fontSize: "24px" }}>↑</span>.
                </span>{" "}
                Now ready for your project. Scroll to learn how we do it & why
                it matters for you.
              </ExpandableText>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-text-box">
              <div className="intro-btn style2 box-shadow">Any Platform</div>
              <ul className="logo" style={{ marginBottom: "40px" }}>
                <li>
                  <img src={IOS} alt="Pandigita" />
                </li>
                <li>
                  <img src={Android} alt="Pandigita" />
                </li>
                <li>
                  <img src={WWW} alt="Pandigita" />
                </li>
              </ul>
              <ExpandableText>
                <span>
                  Drastic time, cost & organizational efficiencies using 3-in-1
                  technology.
                </span>{" "}
                Scroll to learn more
              </ExpandableText>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-bg"></div>
    </section>
  )
}

export default IntroSection
