import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import MblImg from "../../images/project1-mbl.png"

export const getImage = graphql`
  {
    Project1: file(relativePath: { eq: "project1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Project1 = () => {
  const data = useStaticQuery(getImage)
  return (
    <>
      <section className="project-section section-1" id="work-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Some of Our Work</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 d-lg-none">
              <div className="small-media-list">
                <img src={MblImg} alt="Pandigita" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="project-text">
                <div className="topic">
                  NDPAY - FINTECH - IOS, ANDROID & WEB
                </div>
                <h3>A Global Fintech on all Platforms</h3>
                <p>
                  ndpay has launched a global fintech whitelabel solution,
                  starting with the brand OneFor. We created the complete
                  mobile- and web app frontend for them. Using react-native-web,
                  we wrote the whole app once and then compiled it to iOS,
                  Android and - yes - web!
                </p>
                <p className="ul-title">OUR WORK</p>
                <ul>
                  <li>
                    Complete mobile app and web app (frontend), compiled from
                    the same source code.
                  </li>
                  <li>
                    Features to transfer money, split bills, view transactions,
                    credit card management, ...
                  </li>
                  <li>
                    Including Biometric Login, advanced security (auth) measures
                  </li>
                  <li>Advanced OCR functionality (Passport/ID scan)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="right-part">
          <div className="media-list">
            <div className="project1-img">
              <Img
                fluid={data.Project1.childImageSharp.fluid}
                alt="Pandigita"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Project1
